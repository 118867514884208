const Breakponts = {
  values: {
    xs: 0,
    sm: 926,
    md: 1224,
    lg: 1500,
    xl: 10000,
  },
};

export default Breakponts;
