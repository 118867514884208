// export const size = {
//   desktop: "80vw",
//   tablet: "90vw",
//   mobile: "95vw",
//   largeDesktop: "80vw",
//   largelargeDesktop: "80vw",
// };

export const width = {
  xs: "90vw",
  sm: "95vw",
  md: "90vw",
  lg: "80vw",
};
