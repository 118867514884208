import { Box, IconButton, useTheme } from "@mui/material";
import React from "react";
import Redirect from "../../Helpers/Redirect";
import routes from "../../Router/routes";
import { selectLogin } from "../../Toolkit/Login/LoginSlice";
import { useSelector } from "react-redux";

interface LOGO {
  dark?: boolean;
}

const Logo: React.FC<LOGO> = ({ dark = false }) => {
  const { palette } = useTheme();
  const { isAuthenticated } = useSelector(selectLogin);
  const link = isAuthenticated ? routes.home + routes.dashboard : routes.home;
  return (
    <Redirect link={link}>
      <IconButton>
        <Box
          sx={{
            width: "80px",
            padding: "10px 5px",
            borderRadius: "30px",
            backgroundColor: dark ? palette.primary.main : "white",
          }}
        >
          {dark ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="1920"
              zoomAndPan="magnify"
              viewBox="0 0 1440 809.999993"
              height="1080"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
              style={{ width: "80%", height: "100%" }}
            >
              <defs>
                <g />
                <clipPath id="489f59329e">
                  <path
                    d="M 599 470 L 841 470 L 841 735.75 L 599 735.75 Z M 599 470 "
                    clipRule="nonzero"
                  />
                </clipPath>
                <clipPath id="e63005e266">
                  <path
                    d="M 548.796875 411.75 L 891.546875 411.75 L 891.546875 584 L 548.796875 584 Z M 548.796875 411.75 "
                    clipRule="nonzero"
                  />
                </clipPath>
              </defs>
              <rect
                x="-144"
                width="1728"
                fill="#ffffff"
                y="-80.999999"
                height="971.999992"
                fillOpacity="1"
              />
              <rect
                x="-144"
                width="1728"
                fill="#ffffff"
                y="-80.999999"
                height="971.999992"
                fillOpacity="1"
              />
              <rect
                x="-144"
                width="1728"
                fill="#0a9881"
                y="-80.999999"
                height="971.999992"
                fillOpacity="1"
              />
              <g clipPath="url(#489f59329e)">
                <path
                  fill="#ffffff"
                  d="M 840.5 576.445312 Z M 840.5 576.445312 L 720.210938 470.019531 L 599.84375 576.488281 L 599.84375 728.621094 C 599.84375 732.550781 603.023438 735.703125 606.957031 735.703125 L 682.179688 735.703125 L 682.179688 669 C 682.179688 665.070312 685.332031 661.894531 689.265625 661.894531 L 751.074219 661.894531 C 755.007812 661.894531 758.164062 665.070312 758.164062 669 L 758.164062 735.703125 L 833.40625 735.703125 C 837.339844 735.703125 840.496094 732.550781 840.496094 728.621094 L 840.496094 576.4375 Z M 599.84375 576.488281 Z M 599.84375 576.488281 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
              <g clipPath="url(#e63005e266)">
                <path
                  fill="#ffffff"
                  d="M 719.507812 411.777344 L 548.796875 562.773438 L 566.78125 583.039062 L 720.210938 447.320312 L 873.601562 583.039062 L 891.546875 562.773438 L 720.875 411.777344 L 720.210938 412.542969 L 719.511719 411.777344 Z M 719.507812 411.777344 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
              <path
                fill="#ffffff"
                d="M 599.84375 433.511719 L 643.121094 433.511719 L 642.742188 459.117188 L 599.84375 497.832031 Z M 599.84375 433.511719 "
                fillOpacity="1"
                fillRule="nonzero"
              />
              <g fill="#ffffff" fillOpacity="1">
                <g transform="translate(250.60429, 352.238374)">
                  <g>
                    <path d="M 189.542969 0 L 247.898438 0 L 122.414062 -143.914062 L 239.125 -263.253906 L 182.523438 -263.253906 L 86.875 -162.78125 L 71.957031 -162.78125 L 71.957031 -263.253906 L 28.957031 -263.253906 L 28.957031 0 L 71.957031 0 L 71.957031 -124.167969 L 85.996094 -124.167969 Z M 189.542969 0 " />
                  </g>
                </g>
              </g>
              <g fill="#ffffff" fillOpacity="1">
                <g transform="translate(492.360463, 352.238374)">
                  <g>
                    <path d="M 154.003906 3.511719 C 228.59375 3.511719 289.140625 -57.039062 289.140625 -131.628906 C 289.140625 -206.21875 228.59375 -266.765625 154.003906 -266.765625 C 79.414062 -266.765625 18.867188 -206.21875 18.867188 -131.628906 C 18.867188 -57.039062 79.414062 3.511719 154.003906 3.511719 Z M 154.003906 -39.050781 C 102.230469 -39.050781 61.425781 -79.414062 61.425781 -131.628906 C 61.425781 -183.839844 102.230469 -224.207031 154.003906 -224.207031 C 206.21875 -224.207031 246.582031 -183.839844 246.582031 -131.628906 C 246.582031 -79.414062 206.21875 -39.050781 154.003906 -39.050781 Z M 154.003906 -39.050781 " />
                  </g>
                </g>
              </g>
              <g fill="#ffffff" fillOpacity="1">
                <g transform="translate(800.369232, 352.238374)">
                  <g>
                    <path d="M 150.933594 -114.515625 C 183.839844 -121.535156 207.972656 -150.496094 207.972656 -186.035156 C 207.972656 -229.03125 174.625 -263.253906 132.066406 -263.253906 L 28.957031 -263.253906 L 28.957031 0 L 71.957031 0 L 71.957031 -110.566406 L 108.375 -110.566406 L 178.136719 0 L 226.398438 0 Z M 71.957031 -147.863281 L 71.957031 -223.328125 L 126.800781 -223.328125 C 149.179688 -223.328125 165.851562 -207.972656 165.851562 -185.59375 C 165.851562 -163.21875 149.179688 -147.863281 126.800781 -147.863281 Z M 71.957031 -147.863281 " />
                  </g>
                </g>
              </g>
              <g fill="#ffffff" fillOpacity="1">
                <g transform="translate(1027.646363, 352.238374)">
                  <g>
                    <path d="M 31.152344 0 L 74.148438 0 L 74.148438 -263.253906 L 31.152344 -263.253906 Z M 31.152344 0 " />
                  </g>
                </g>
              </g>
            </svg>
          ) : (
            <svg
              style={{ width: "80%", height: "100%" }}
              xmlns="http://www.w3.org/2000/svg"
              xmlnsXlink="http://www.w3.org/1999/xlink"
              width="1920"
              zoomAndPan="magnify"
              viewBox="0 0 1440 809.999993"
              height="1080"
              preserveAspectRatio="xMidYMid meet"
              version="1.0"
            >
              <defs>
                <g />
                <clipPath id="e5aae3ac66">
                  <path
                    d="M 599 470 L 841 470 L 841 735.75 L 599 735.75 Z M 599 470 "
                    clipRule="nonzero"
                  />
                </clipPath>
                <clipPath id="87a1e3d6d8">
                  <path
                    d="M 548.796875 411.75 L 891.546875 411.75 L 891.546875 584 L 548.796875 584 Z M 548.796875 411.75 "
                    clipRule="nonzero"
                  />
                </clipPath>
              </defs>
              <rect
                x="-144"
                width="1728"
                fill="#ffffff"
                y="-80.999999"
                height="971.999992"
                fillOpacity="1"
              />
              <rect
                x="-144"
                width="1728"
                fill="#ffffff"
                y="-80.999999"
                height="971.999992"
                fillOpacity="1"
              />
              <g clipPath="url(#e5aae3ac66)">
                <path
                  fill="#0a9881"
                  d="M 840.5 576.445312 Z M 840.5 576.445312 L 720.210938 470.019531 L 599.84375 576.488281 L 599.84375 728.621094 C 599.84375 732.550781 603.023438 735.703125 606.957031 735.703125 L 682.179688 735.703125 L 682.179688 669 C 682.179688 665.070312 685.332031 661.894531 689.265625 661.894531 L 751.074219 661.894531 C 755.007812 661.894531 758.164062 665.070312 758.164062 669 L 758.164062 735.703125 L 833.40625 735.703125 C 837.339844 735.703125 840.496094 732.550781 840.496094 728.621094 L 840.496094 576.4375 Z M 599.84375 576.488281 Z M 599.84375 576.488281 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
              <g clipPath="url(#87a1e3d6d8)">
                <path
                  fill="#0a9881"
                  d="M 719.507812 411.777344 L 548.796875 562.773438 L 566.78125 583.039062 L 720.210938 447.320312 L 873.601562 583.039062 L 891.546875 562.773438 L 720.875 411.777344 L 720.210938 412.542969 L 719.511719 411.777344 Z M 719.507812 411.777344 "
                  fillOpacity="1"
                  fillRule="nonzero"
                />
              </g>
              <path
                fill="#0a9881"
                d="M 599.84375 433.511719 L 643.121094 433.511719 L 642.742188 459.117188 L 599.84375 497.832031 Z M 599.84375 433.511719 "
                fillOpacity="1"
                fillRule="nonzero"
              />
              <g fill="#0a9881" fillOpacity="1">
                <g transform="translate(250.60429, 352.238381)">
                  <g>
                    <path d="M 189.542969 0 L 247.898438 0 L 122.414062 -143.914062 L 239.125 -263.253906 L 182.523438 -263.253906 L 86.875 -162.78125 L 71.957031 -162.78125 L 71.957031 -263.253906 L 28.957031 -263.253906 L 28.957031 0 L 71.957031 0 L 71.957031 -124.167969 L 85.996094 -124.167969 Z M 189.542969 0 " />
                  </g>
                </g>
              </g>
              <g fill="#0a9881" fillOpacity="1">
                <g transform="translate(492.360463, 352.238381)">
                  <g>
                    <path d="M 154.003906 3.511719 C 228.59375 3.511719 289.140625 -57.039062 289.140625 -131.628906 C 289.140625 -206.21875 228.59375 -266.765625 154.003906 -266.765625 C 79.414062 -266.765625 18.867188 -206.21875 18.867188 -131.628906 C 18.867188 -57.039062 79.414062 3.511719 154.003906 3.511719 Z M 154.003906 -39.050781 C 102.230469 -39.050781 61.425781 -79.414062 61.425781 -131.628906 C 61.425781 -183.839844 102.230469 -224.207031 154.003906 -224.207031 C 206.21875 -224.207031 246.582031 -183.839844 246.582031 -131.628906 C 246.582031 -79.414062 206.21875 -39.050781 154.003906 -39.050781 Z M 154.003906 -39.050781 " />
                  </g>
                </g>
              </g>
              <g fill="#0a9881" fillOpacity="1">
                <g transform="translate(800.369232, 352.238381)">
                  <g>
                    <path d="M 150.933594 -114.515625 C 183.839844 -121.535156 207.972656 -150.496094 207.972656 -186.035156 C 207.972656 -229.03125 174.625 -263.253906 132.066406 -263.253906 L 28.957031 -263.253906 L 28.957031 0 L 71.957031 0 L 71.957031 -110.566406 L 108.375 -110.566406 L 178.136719 0 L 226.398438 0 Z M 71.957031 -147.863281 L 71.957031 -223.328125 L 126.800781 -223.328125 C 149.179688 -223.328125 165.851562 -207.972656 165.851562 -185.59375 C 165.851562 -163.21875 149.179688 -147.863281 126.800781 -147.863281 Z M 71.957031 -147.863281 " />
                  </g>
                </g>
              </g>
              <g fill="#0a9881" fillOpacity="1">
                <g transform="translate(1027.646363, 352.238381)">
                  <g>
                    <path d="M 31.152344 0 L 74.148438 0 L 74.148438 -263.253906 L 31.152344 -263.253906 Z M 31.152344 0 " />
                  </g>
                </g>
              </g>
            </svg>
          )}
        </Box>
      </IconButton>
    </Redirect>
  );
};

export default Logo;
