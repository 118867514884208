const palette = {
  primary: {
    main: "#069881",
  },
  secondary: {
    main: "#eef8f6",
    light:'#def2ee',
  },
};

export default palette;
